<script setup>

  // Validációhoz, form generáláshoz:
  // import { useForm, useFieldValue, Form, Field, ErrorMessage } from 'vee-validate';
  import { Form, Field, ErrorMessage } from 'vee-validate';

  // behuzzuk a generalando form schema-jat es a submit esemeny fuggvenyet (a parent-ből)
  // props
  const {
    schema, 
    fieldChange,
    submitForm, 
    submitButtonPlaceOnThe, 
    submitOnSelect,
    initialValues, 
    fieldPairs,
    showPasswordToggle
   } = defineProps({
      // , required: true
      schema: { type: Object, default: null },
      fieldChange: { type: Function },
      submitForm: { type: Function },
      submitButtonPlaceOnThe: { type: String, default: null },
      submitOnSelect: { type: String, default: null },
      initialValues: { type: Object, default: {} },
      fieldPairs: { type: Object, default: {} },
      showPasswordToggle: { type: Function },
    });


  // const { 
  //   schema, 
  //   fieldChange,
  //   submitForm, 
  //   submitButtonPlaceOnThe, 
  //   submitOnSelect,
  //   initialValues, 
  //   fieldPairs,
  //   showPasswordToggle
  //  } = defineProps([
  //     'schema', 
  //     'fieldChange',
  //     'submitForm', 
  //     'submitButtonPlaceOnThe', 
  //     'submitOnSelect', 
  //     'initialValues', 
  //     'fieldPairs',
  //     'showPasswordToggle'
  //   ]);

   
  // ha egy mező értéke változik, meghívja ezt a fgv-t,
  //  ami pedig a props-ban érkező fieldChange -et.
  const changeField = (name, value) => {
    if(typeof fieldChange === 'function') {
      fieldChange(name, value);
    }    
  };
   
  // const fieldPairs = {
  //   firstPair: ['firstname', 'lastname']
  // };

  // mezők elrendezéséhez: párok egymás mellé állításhoz:
  const shouldPlaceNextToEachOther = (fieldPairs, fieldName) => {
  if(!fieldPairs) {
    return 'w-full';
  }
  for (const pairName in fieldPairs) {
    if (fieldPairs[pairName].includes(fieldName)) {
      return 'w-1/'+fieldPairs[pairName].length;
    }
  }
  return 'w-full';
};

</script>

<template>
  <div :initialValues="initialValues">
    <Form v-slot="{ values, meta, errors }" @submit="submitForm">
      <div class="flex flex-wrap">
        <div
          class="p-1"
          v-for="{ 
            as, type, id, name, label, hint, children, options, validateOnInput, validateOnChange, validateOnMount, ...attrs 
          } in schema.fields"
          :key="name"
          :class="shouldPlaceNextToEachOther(fieldPairs, name)"
        >
          <label class="block text-grey-darker text-sm mb-2" :for="name">
            {{ label }}
          </label>
          <div v-if="type === 'radio'">
            <div class="block text-grey-darker text-sm mb-2">
              {{ label }}
            </div>
            <div class="relative">
              <div v-for="option in options" :key="option.value" class="my-1">
                <label :for="id + '-' + option.value">
                  <Field 
                    v-if="submitOnSelect === name"
                    type="radio"
                    @click="submitForm"
                    :name="name"
                    :id="id + '-' + option.value"
                    :value="option.value"
                    class="disabled:opacity-50"
                  />
                  <Field 
                    v-else
                    type="radio"
                    :name="name"
                    :id="id + '-' + option.value"
                    :value="option.value"
                    class="disabled:opacity-50"
                  />
                  <span class="ml-2">{{ option.label }}</span>
                </label>
              </div>
            </div>
            <span v-if="errors[name]" class="text-xs font-sans font-light px-1">
              <ErrorMessage :name="name" />
            </span>
            <span v-if="errors[name] === undefined && hint && hint.length" class="text-xs font-sans font-light px-1">{{ hint }}</span>
          </div>
          <div v-else class="relative">
            <Field 
              :as="as" 
              :id="id"
              :key="id"
              :name="name" 
              :type="type"
              :validateOnInput="validateOnInput || false"
              :validateOnChange="validateOnChange || false"
              :validateOnMount="validateOnMount || false"
              @input="changeField(name, values[name])"
              v-bind="attrs"
              :class="[errors[name] ? 'border-red-dark border' : '', as === 'textarea' ? 'h-48' : 'h-10']"
              class="
                block 
                w-full 
                bg-boxbg-light 
                px-3 
                pr-10 
                rounded-t-md 
                rounded-md 
                text-md 
                focus:outline-none 
                text-txt-dark 
                placeholder-txt-dark 
                placeholder-opacity-50 
                appearance-none 
                font-light 
                font-sans 
                disabled:opacity-75 
                disabled:select-none"
            >
              <template v-if="children && children.length">
                <component v-for="({ tag, text, ...childAttrs }, idx) in children"
                  :key="idx"
                  :is="tag"
                  v-bind="childAttrs"
                >
                  {{ text }}
                </component>
              </template>
            </Field>
            <LazyUiInputIconShowPassword v-if="showPasswordToggle && id==='password'" @click="showPasswordToggle()" :class="errors[name] ? 'pr-12' : 'pr-3'" />
            <LazyUiInputIconError v-if="errors[name]" />
          </div>
          <ErrorMessage as="div" :name="name" v-slot="{ message }">
            <span class="text-xs font-sans font-light px-1">{{ message }}</span>
          </ErrorMessage>
          <span v-if="errors[name] === undefined && hint && hint.length" class="text-xs font-sans font-light px-1">{{ hint }}</span>
        </div>
      </div>
      <slot name="infobarbetween" class="px-1 py-4 flex justify-between gap-1"></slot>
      <div class="px-1 py-4 flex justify-between gap-1" v-if="submitButtonPlaceOnThe!=='hide'">
        <div>
          <slot name="submitleftside">
            <UiSubmitButton v-if="submitButtonPlaceOnThe === 'left'" :disabled="meta.valid ? false : true">
              <slot name="submitbuttonlabel">{{ submitButtonLabel }}</slot>
              <slot name="submitbuttonincon">
              </slot>
            </UiSubmitButton>          
          </slot>
        </div>
        <div>
          <slot name="submitcenterside">
            <UiSubmitButton v-if="submitButtonPlaceOnThe === 'center'" :disabled="meta.valid ? false : true">
              <slot name="submitbuttonlabel">{{ submitButtonLabel }}</slot>
              <slot name="submitbuttonincon">
              </slot>
            </UiSubmitButton>
          </slot>
        </div>
        <div>
          <slot name="submitrightside">
            <UiSubmitButton v-if="submitButtonPlaceOnThe === 'right'" :disabled="meta.valid ? false : true">
              <slot name="submitbuttonlabel">{{ submitButtonLabel }}</slot>
              <slot name="submitbuttonincon">
              </slot>
            </UiSubmitButton>
          </slot>
        </div>
      </div>
    </Form>
  </div>
</template>
